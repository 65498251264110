*{
  margin:0;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Poor Story", cursive;
}

.image-box {
  position: relative;
  margin: auto;
  overflow: hidden;
  max-width: 800px;
  border-radius: 10px;
}

.image-box img {
  max-width: 100%;
  transition: all 0.3s;
  display: block;
  height: auto;
  transform: scale(1);
  border-radius: 5%;
}

.image-box:hover img {
  transform: scale(2);
}
